/* Riot v9.3.0, @license MIT */
import { PLUGINS_SET } from '../dependencies/@riotjs/util/constants.js';

/**
 * Run the component instance through all the plugins set by the user
 * @param   {Object} component - component instance
 * @returns {Object} the component enhanced by the plugins
 */
function runPlugins(component) {
  return [...PLUGINS_SET].reduce((c, fn) => fn(c) || c, component)
}

export { runPlugins };
