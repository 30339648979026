<heading-page>
    <div class="md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
            <h2>{ state.text }</h2>
        </div>
    </div>

    <script>
        import eventBus from '/src/utils/event-bus'
        import * as constants from '/src/utils/constants'

        export default {

            onBeforeMount(props, state) {
                this.state = {
                    text: props.text,
                    search_text: ''
                }
            }
        }

    </script>
</heading-page>