import Parse from './parse-init';
import moment from 'moment-with-locales-es6';
import eventBus from '/src/utils/event-bus';
import * as constants from '/src/utils/constants';

const Messaggio = Parse.Object.extend("Messaggio");

const StoreService = {

    getUser() {
        let user = null;
        try {
            user = Parse.User.current();
        } catch (e) {
            console.error(e);
        }
        return user;
    },

    async login(username, password) {
        try {
            const user = await Parse.User.logIn(username, password, {usePost: false});
            eventBus.trigger(constants.LOGIN_SUCCESS, {
                user: user
            });
        } catch (e) {
            eventBus.trigger(constants.LOGIN_FAILED, {
                error: e
            });
        }
    },

    async logout() {
        try {
            await Parse.User.logOut();
            eventBus.trigger(constants.LOGOUT_SUCCESS);
        } catch (e) {
            eventBus.trigger(constants.LOGOUT_FAILED, {
                error: e
            })
        }
    },

    async userAccept() {
        try {
            const d = moment().toISOString();
            const user = Parse.User.current();
            user.set("accettazione", d);
            await user.save();
            eventBus.trigger(constants.DISCLAIMER_ACCETTATO, {
                timestamp: d
            })
        } catch (error) {
            console.error("Errore nel salvataggio dell'item:", error);
        }
    },

    async getMessaggi() {
        try {
            const d = moment().format("YYYYMMDD");
            const user = Parse.User.current();
            const query = new Parse.Query(Messaggio);
            query.equalTo("utente", user);
            query.equalTo("giorno", d);
            query.limit(1000);
            query.ascending("createdAt");
            const messaggi = await query.find();
            console.log(eventBus);
            eventBus.trigger(constants.LOADED_MESSAGES, {
                messaggi: messaggi
            })
        } catch (error) {
            console.error("Errore nel recupero dei messaggi:", error);
        }
    },

    async startChat() {
        try {
            const result = await Parse.Cloud.run("startChat");
            if (result.success) {
                eventBus.trigger(constants.NEW_MESSAGE, {
                    messaggio: result.messaggio
                });
            }
        } catch (error) {
            console.error("Errore nel salvataggio dell'item:", error);
        }
    },

    async sendMessaggio(testo) {
        try {
            let result = await Parse.Cloud.run("sendMessage", {
                message: testo
            });
            eventBus.trigger(constants.NEW_MESSAGE, {
                messaggio: result.messaggio
            });
            // Chiamata alla funzione Cloud Code per ottenere la risposta dell'AI
            result = await Parse.Cloud.run("getAIResponse");
            eventBus.trigger(constants.NEW_MESSAGE, {
                messaggio: result.messaggio
            });
        } catch (error) {
            console.error("Errore nel salvataggio dell'item:", error);
        }
    }
};

export default StoreService;
