const _temp0 = require("../components/global/chat.riot");
const _temp1 = require("../components/global/dashboard.riot");
const _temp2 = require("../components/global/disclaimer.riot");
const _temp3 = require("../components/global/heading-page.riot");
const _temp4 = require("../components/global/input-area.riot");
const _temp5 = require("../components/global/item-nav.riot");
const _temp6 = require("../components/global/login.riot");
const _temp7 = require("../components/global/logout-button.riot");
const _temp8 = require("../components/global/messaggio.riot");
const _temp9 = require("../components/global/raw.riot");
module.exports = {
  "chat": _temp0,
  "dashboard": _temp1,
  "disclaimer": _temp2,
  "heading-page": _temp3,
  "input-area": _temp4,
  "item-nav": _temp5,
  "login": _temp6,
  "logout-button": _temp7,
  "messaggio": _temp8,
  "raw": _temp9
}