/* Riot v9.3.0, @license MIT */
import { IS_DIRECTIVE } from '../dependencies/@riotjs/util/constants.js';
import { get } from '../dependencies/bianco.attr/index.next.js';

/**
 * Get the tag name of any DOM node
 * @param   {HTMLElement} element - DOM node we want to inspect
 * @returns {string} name to identify this dom node in riot
 */
function getName(element) {
  return get(element, IS_DIRECTIVE) || element.tagName.toLowerCase()
}

export { getName };
