<messaggio>

    <div class="flex { props.direzione == 1 ? 'justify-end' : 'justify-start' } mb-4">
        <div class="max-w-xs mx-2 p-3 rounded-lg shadow-md text-white 
             { props.direzione == 1 ? 'bg-lime-500' : 'bg-gray-500' }">
            <p each="{line in state.displayedText.split('\n')}">
                <span>{line}</span><br if="{line.length > 0}"/>
            </p>
            <p class="text-xs mt-2 text-right text-gray-100">{ moment(props.timestamp).format('HH:mm') }</p>
        </div>
    </div>

    <script>
        import moment from 'moment-with-locales-es6'

        export default {
            moment: moment,

            onBeforeMount(props, state) {
                moment.locale('it')
                this.state = {
                    displayedText: ''
                }
            },

            onMounted() {
                if (this.props.last === 1 && this.props.direzione === -1) {
                    this.animateText()
                } else {
                    this.update({
                        displayedText: this.props.testo
                    });
                }
            },

            /*
             onUpdated() {
             if (this.props.direzione === -1) { // Solo per le risposte dell'AI
             this.speak(this.state.displayedText);
             }
             },
             */

            animateText() {
                const fullText = this.props.testo;
                let currentIndex = 0;

                const interval = setInterval(() => {
                    if (currentIndex < fullText.length) {
                        this.update({
                            displayedText: this.state.displayedText + fullText[currentIndex]
                        });
                        currentIndex++;
                    } else {
                        clearInterval(interval);
                    }
                }, 20); // Modifica il valore 50 per cambiare la velocità dell'animazione
            },

            /*
             speak(text) {
             if ('speechSynthesis' in window) {
             const speech = new SpeechSynthesisUtterance(text);
             speech.lang = 'it-IT'; // Imposta la lingua a italiano
             window.speechSynthesis.speak(speech);
             } else {
             console.warn('Il browser non supporta la sintesi vocale.');
             }
             }
             */
        }
    </script>
</messaggio>
