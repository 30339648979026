<dashboard>
    <div>
        <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
        <div id="offcanvas_menu" class="relative z-50 lg:hidden { (!state.transitioning && !state.sidebar_visible) ? 'hidden' : '' }" role="dialog" aria-modal="true" ontransitionend="{ handleTransitionEnd }">
            <div class="fixed inset-0 bg-gray-900/80 transition-opacity ease-linear duration-300 { state.sidebar_visible ? 'opacity-100' : 'opacity-0' }"></div>
            <div class="fixed inset-0 flex">
                <div class="relative mr-16 flex w-full max-w-xs flex-1 transition ease-in-out duration-300 transform { state.sidebar_visible ? 'translate-x-0' : '-translate-x-full' }">
                    <div class="absolute left-full top-0 flex w-16 justify-center pt-5 ease-in-out duration-300 { state.sidebar_visible ? 'opacity-100' : 'opacity-0' }">
                        <button type="button" class="-m-2.5 p-2.5" onclick="{
                                    toggle_sidebar
                                }">
                            <span class="sr-only">{ sidebar_visible ? 'Chiudi' : 'Apri' } sidebar</span>
                            <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                    <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-sky-700 px-6 pb-2">
                        <div class="flex h-16 shrink-0 items-center">
                            <img class="h-8 w-auto" src="{ logo }" alt="Counselor AI">
                        </div>
                        <nav class="flex flex-1 flex-col">
                            <div class="flex flex-1 flex-col gap-y-7">
                                <ul role="list" class="-mx-2 space-y-1">
                                    <li each="{ item in item_navs }" onclick="{
                                                handleCloseSidebar
                                            }"><item-nav ckey="{ item.id }" title="{ item.title }" link="{ item.link }" role="{ item.role }"></item-nav></li>
                                    <li><logout-button /></li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>

        <!-- Static sidebar for desktop -->
        <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-sky-700 px-6">
                <div class="flex h-16 shrink-0 items-center">
                    <img class="h-8 w-auto" src="{ logo }" alt="Counselor AI"> <span class="text-white ml-2">Counselor AI</span>
                </div>
                <nav class="flex flex-1 flex-col">
                    <div class="flex flex-1 flex-col gap-y-7 justify-between">
                        <ul role="list" class="-mx-2 space-y-1">
                            <li each="{ item in item_navs }"><item-nav ckey="{ item.id }" title="{ item.title }" link="{ item.link }" role="{ item.role }"></item-nav></li>
                        </ul>
                        <ul role="list" class="-mx-2 space-y-1 pb-2">
                            <li><logout-button /></li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>

        <div class="sticky top-0 z-40 flex items-center gap-x-6 bg-sky-700 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
            <button type="button" class="-m-2.5 p-2.5 text-blue-200 lg:hidden" onclick="{
                        toggle_sidebar
                    }">
                <span class="sr-only">{ sidebar_visible ? 'Chiudi' : 'Apri' } sidebar</span>
                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
            </button>
            <div class="flex-1 text-sm font-semibold leading-6 text-white">Dashboard</div>
        </div>

        <main class="lg:pl-72">
            <div class="px-4 sm:px-6 lg:px-8">
                <route if="{ state.accettato }" path="/" on-before-mount="{ beforeRoute }">
                    <chat></chat>
                </route>
                <route path="/{ ckey_disclaimer }" on-before-mount="{ beforeRoute }">
                    <disclaimer></disclaimer>
                </route>
            </div>
        </main>
        <modal-error ckey="error"></modal-error>
    </div>

    <script>
        import { router } from '@riotjs/route'
        import StoreService from '/src/services/store'
        import eventBus from '/src/utils/event-bus'
        import * as constants from '/src/utils/constants'
        import logo from '/assets/images/logo.png'

        export default {

            onBeforeMount(props, state) {

                document.documentElement.className = "h-full bg-white"
                document.body.classList = "h-full"

                this.logo = logo
                this.disclaimer_path = '/' + constants.KEY_DISCLAIMER

                this.item_navs = [
                    {
                        id: constants.KEY_CHAT,
                        title: 'Chat',
                        link: '/',
                        role: [constants.ROLE_USER, constants.ROLE_ADMIN]
                    }, {
                        id: constants.KEY_DISCLAIMER,
                        title: 'Disclaimer',
                        link: '/' + constants.KEY_DISCLAIMER,
                        role: [constants.ROLE_USER, constants.ROLE_ADMIN]
                    }
                ]

                this.ckey_chat = constants.KEY_CHAT
                this.ckey_disclaimer = constants.KEY_DISCLAIMER

                this.state = {
                    sidebar_visible: false,
                    panel_hidden: true,
                    current_item: constants.KEY_CHAT,
                    user: StoreService.getUser(),
                    accettato: (StoreService.getUser() && StoreService.getUser().get("accettazione")) ? true : false
                }

                if (!this.state.accettato) {
                    this.goToDisclaimer()
                }

                router.on.value((path) => {
                    console.log(path)
                    if (!this.state.accettato && path !== this.disclaimer_path) {
                        this.goToDisclaimer()
                    }
                })

                eventBus.on(constants.DISCLAIMER_ACCETTATO, this.disclaimerAccettato)
            },

            onBeforeUnmount(props, state) {
                eventBus.off(constants.DISCLAIMER_ACCETTATO, this.disclaimerAccettato)
            },

            toggle_sidebar() {
                this.update({
                    transitioning: true,
                    sidebar_visible: !this.state.sidebar_visible
                })
            },

            handleCloseSidebar() {
                this.update({
                    transitioning: true,
                    sidebar_visible: false
                })
            },

            beforeRoute(url) {
                const pathname = url.pathname
                eventBus.trigger(constants.UPDATE_NAV, {
                    pathname: pathname
                })
            },

            handleTransitionEnd(e) {
                if (!this.state.sidebar_visible) {
                    this.update({
                        transitioning: false
                    })
                }
            },

            disclaimerAccettato(result) {
                this.update({
                    accettato: true
                })
            },

            goToDisclaimer() {
                history.pushState({}, '', this.disclaimer_path)
                router.push(this.disclaimer_path)
            }
        }
    </script>
</dashboard>