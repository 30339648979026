export const LOGIN_SUCCESS = 'login_success';
export const LOGIN_FAILED = 'login_failed';
export const LOGOUT_SUCCESS = 'logout_success';
export const LOGOUT_FAILED = 'logout_failed';
export const DISCLAIMER_ACCETTATO = 'disclaimer_accettato';
export const LOADED_MESSAGES = 'loaded_messages';
export const NEW_MESSAGE = 'new_message';
export const UPDATE_NAV = 'update_nav'

export const ROLE_USER = 'role_user'
export const ROLE_ADMIN = 'role_admin'

export const KEY_CHAT = 'chat'
export const KEY_DISCLAIMER = 'disclaimer'
export const KEY_INPUT_AREA = 'input_area'