<item-nav>
    <div if="{ isVisible() }">
        <a ckey="{ props.ckey }" href="{ props.link }" class="{ state.active ? 'bg-lime-600 text-white' : 'text-blue-200 hover:text-white hover:bg-lime-600' } group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
            <svg if="{ props.ckey === ckey_chat }" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
            </svg>
            <svg if="{ props.ckey === ckey_disclaimer }" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75" />
            </svg>

            { props.title }
        </a>
    </div>

    <script>
        import StoreService from '/src/services/store'
        import eventBus from '/src/utils/event-bus'
        import * as constants from '/src/utils/constants'

        export default {

            ckey_chat: constants.KEY_CHAT,
            ckey_disclaimer: constants.KEY_DISCLAIMER,

            onBeforeMount(props, state) {
                this.state = {
                    active: false,
                    user: StoreService.getUser()
                }
                eventBus.on(constants.UPDATE_NAV, this.handleUpdateNav)
            },

            onBeforeUnmount(props, state) {
                eventBus.off(constants.UPDATE_NAV, this.handleUpdateNav)
            },

            handleUpdateNav(e) {
                if (e.pathname === this.props.link) {
                    this.update({
                        active: true
                    })
                } else {
                    this.update({
                        active: false
                    });
                }
            },

            isVisible() {
                return (this.props.role && this.props.role.length && this.props.role.includes(this.state.user.get('role'))) || !this.props.role || this.props.role.length === 0
            }
        }

    </script>
</item-nav>
